/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #21bd3a;
    border-color: #21bd3a;
}
.container {
    max-height: 100%;
    max-width: 100%;
}
.col-height-xs {
    max-height: 35px;
}

.font_size_xxxs {
    font-size: 8px
}

.font_size_xxs {
    font-size: 14px
}

.max-with-14Col {
    max-width: 5.35%;
}

.max_navbar_height {
    max-height: 50px;
}

.btn-host {
    color: #fff;
    background-color: #21bd3a;
    border-color: #21bd3a;
}

.border-host {
    border-color: #21bd3a;
}
.border-bottom-host {
    border-bottom: 1px solid #21bd3a !important;
}

.badgeCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    background: #fff;
    border: 2px solid;
    color: #666;
    text-align: center;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
    height: calc(1.8125rem + 4px);
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 4px);
}

.input-group-text {
    background-color: #fff;
    min-height: 38px;
    border-right: 0px;
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    padding: 0.1rem 0.2rem;
}

.form-control, .input-group .form-control {
    min-height: 38px;
    height: calc(1.80rem + 2px);
    padding: 2px 8px; /*.2rem .2rem*/
}

.input-group-prepend {
    margin-right: 0px;
    
}

.page-item.active .page-link {
    background-color: #21bd3a;
    border-color: #21bd3a;
}

#pageDropDown {
    color: white;
    background-color: #21bd3a;
    border-color: #21bd3a;
    font-size: 0.875rem;
    line-height: 1.5;
    height: 33px;
}

/* TicketDetails */
.msg-separator {
    display: flex;
    line-height: 1em;
}

.msg-separator::before, .msg-separator::after {
    content: '';
    display: inline-block;
    flex-grow: 1;
    margin-top: 0.5em;
    background: #d0d0d0;
    height: 1px;
    margin-bottom: 30px;
}

.newMsg {
    display: flex;
    min-height: 30px;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}

.newMsg button {
    margin-left: 20px;
    float: right;
}

.newMsg .buttons {
    width: 10%;
    display: flex;
    justify-content: center;
}

.messageBox {
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    padding: 5px 20px;
    min-width: 90%;
    max-width: 100%;
    min-height: 60px;
}

.rdw-editor-main {
    padding-left: 15px;
    padding-right: 15px;
}

.msg-info{
    text-align: right;
    font-size: 12px;
    margin: 0;
}


/* Static Fields */
.internal-btn {
    background-color: #ff3333 !important;
}

.support-btn {
    background-color: #43e842 !important;
}

.notSelected {
    background-color: #c3c3c3 !important;
}

.hotelName::after {
    position: absolute;
    right: 30px;
}

.hotelName, .hotelName:hover {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}

.newMessageCard {
    position: fixed;
    bottom: 10px;
    z-index: 1;
    width: 96%;
    background-color: white;
    left: 2%;
    border-color: #ababab;
    max-height: 50%;
    overflow-x: hidden;
    overflow-y: auto;
}

.newMessageCard::-webkit-scrollbar {
    width: 10px;
}

.newMessageCard::-webkit-scrollbar-track-piece {
    background: #f1f1f1
}

.newMessageCard::-webkit-scrollbar-thumb {
    background: #c1c1c1
}