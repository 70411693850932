
.react-bootstrap-table-pagin2ation-list-hidden {
    display: none;
}

/*Will edit selected date or the endpoints of a range of dates*/
.CalendarDay__selected {
    background: #EDC934;
    color: white;
}

/* Will edit when hovered over. _span style also has this property*/
    .CalendarDay__selected:hover {
        background: #EDC934;
        color: white;
    }


.SingleDatePickerInput_calendarIcon {
    margin: 0px;
    background-color: #e9ecef;
    height: 29px;
    padding: 7px;
}

.DateInput_input__focused {
    border-bottom: 2px solid #EDC934
}

.SingleDatePickerInput_calendarIcon_svg {
    fill : #495057
}

.SingleDatePicker {
    /*box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;*/
}

.DateRangePicker {
    /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;*/
}

.DateRangePickerInput_calendarIcon  {
    margin: 0px;
    height: 32px;
    padding: 6px;
    background-color: white;
}
.SingleDatePickerInput_calendarIcon {
    margin: 0px;
    height: 32px;
    padding: 6px;
    background-color: white;
}

.SingleDatePickerInput__withBorder {
    border-radius: 3px;
    height: 38px;
}
.DateInput_input__small {
    height: 34px
}


.DateRangePickerInput_calendarIcon_svg {
    fill: #495057
}
.DateRangePickerInput__withBorder {
    border-radius: 3px;
    height: 38px;
}
